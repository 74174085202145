import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import clsx from 'clsx';
import { withStyling, IInjectedStylingProps } from './higher-order';
import styles from './select.module.scss';

export type SelectOption = {
	id: number | string;
	label: string;
};

export interface ISelectProps extends IInjectedStylingProps {
	className?: string;
	disabled?: boolean;
	enableCustom?: boolean;
	fixCrossBrowserStyling?: boolean;
	fullWidth?: boolean;
	id?: string;
	isInvalid?: boolean;
	multiple?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	options: SelectOption[];
	placeholder?: string;
	hidePlaceholderStyle?: boolean;
	size?: 'small' | 'medium' | 'big';
	value?: string | number | string[];
	wrapperClassName?: string;
	disabledOptions?: SelectOption[];
	title?: string;
	name?: string;
}

const SelectComponent = (props: ISelectProps): React.JSX.Element => {
	const {
		className,
		disabled,
		enableCustom,
		fixCrossBrowserStyling,
		fullWidth,
		multiple = false,
		options,
		size = props.size || 'small',
		wrapperClassName,
		title,
		name
	} = props;
	const [forceUpdate, setForceUpdate] = useState(0);

	const selectClassnames = clsx(
		className,
		props.isInvalid && props.styling?.getStyle(styles, 'error'),
		props.styling?.getStyle(styles, 'select'),
		size !== 'small' && props.styling?.getStyle(styles, `size${capitalize(size)}`),
		fullWidth && props.styling?.getStyle(styles, 'fullWidth'),
		fixCrossBrowserStyling && props.styling?.getStyle(styles, 'fixCrossBrowserStyling'),
		!props.value && !props.hidePlaceholderStyle && props.styling?.getStyle(styles, 'placeholder')
	);

	const wrapperClassnames = clsx(
		wrapperClassName,
		props.styling?.getStyle(styles, 'customSelect'),
		disabled && props.styling?.getStyle(styles, 'disabled')
	);

	const values = props.value || props.placeholder;

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
		if (multiple) {
			// The counter makes sure the multiselect list is rerendered. On mobile this wil then update the selected items counter. Otherwise this won't work.
			setForceUpdate(forceUpdate + 1);
		}
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const renderSelect = (): React.JSX.Element => (
		<select
			className={selectClassnames}
			disabled={disabled}
			id={props.id}
			onChange={handleChange}
			value={values}
			multiple={multiple}
			title={title}
			name={name}
		>
			{props.placeholder && (
				<option
					key={props.placeholder}
					value={props.placeholder}
					className={props.styling?.getStyle(styles, 'placeholder')}
				>
					{props.placeholder}
				</option>
			)}
			{options.map((option: SelectOption) => (
				<option
					key={`${option.id}-${forceUpdate}`}
					value={option.id}
					className={props.styling?.getStyle(styles, 'option')}
					disabled={optionIsDisabled(option, props.disabledOptions)}
				>
					{option.label}
				</option>
			))}
		</select>
	);

	return enableCustom ? <div className={wrapperClassnames}>{renderSelect()}</div> : renderSelect();
};

const Select = withStyling(SelectComponent);
export { Select };

const optionIsDisabled = (option: SelectOption, disabledOptions?: SelectOption[]): boolean => {
	if (!disabledOptions) return false;

	return disabledOptions.findIndex((disabledOption) => disabledOption.id === option.id) > -1;
};
