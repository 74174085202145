import { TextField as TextFieldCore, ITextFieldProps as ITextFieldPropsCore } from '../core/text-field';

import styles from './text-field.module.scss';

export interface ITextFieldProps extends ITextFieldPropsCore {}

const TextField = (props: ITextFieldProps): React.JSX.Element => {
	return <TextFieldCore {...props} propStyles={styles} />;
};

export { TextField };
