import clsx from 'clsx';
import { withStyling, IInjectedStylingProps } from './higher-order';
import styles from './text-field.module.scss';

export interface ITextFieldProps extends IInjectedStylingProps {
	ariaLabel?: string;
	disabled?: boolean;
	icon?: React.JSX.Element;
	iconPosition?: 'left' | 'right';
	id?: string;
	isInvalid?: boolean;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	placeholder?: string;
	readOnly?: boolean;
	type: string;
	value: string;
	deleteIcon?: React.JSX.Element;
	onDelete?: () => void;
	maxLength?: number;
	onKeyDown?: (e: React.KeyboardEvent) => void;
	showLength?: boolean;
}

const component = (props: ITextFieldProps): React.JSX.Element => {
	const { iconPosition = 'left' } = props;

	const inputClassnames = clsx(
		props.isInvalid && props.styling?.getStyle(styles, 'error'),
		props.readOnly && props.styling?.getStyle(styles, 'readOnly'),
		props.disabled && props.styling?.getStyle(styles, 'disabled'),
		props.icon &&
			(iconPosition === 'left'
				? props.styling?.getStyle(styles, 'withIconLeft')
				: props.styling?.getStyle(styles, 'withIconRight')),
		props.showLength && props.styling?.getStyle(styles, 'showLengthInput')
	);

	const inputWrapperClassnames = clsx(props.styling?.getStyle(styles, 'textFieldInput'));

	const iconClassNames = clsx(
		props.styling?.getStyle(styles, 'icon'),
		iconPosition === 'right' && props.styling?.getStyle(styles, 'iconRight')
	);

	return (
		<div className={inputWrapperClassnames}>
			<input
				aria-label={props.ariaLabel}
				className={inputClassnames}
				type={props.type}
				name={props.id}
				id={props.id}
				onChange={props.onChange}
				value={props.value}
				placeholder={props.placeholder || ''}
				disabled={props.disabled}
				readOnly={props.readOnly}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				maxLength={props.maxLength}
				onKeyDown={props.onKeyDown}
			/>
			{props.icon && <span className={iconClassNames}>{props.icon}</span>}
			{props.deleteIcon && props.value && (
				<span role="button" className={props.styling?.getStyle(styles, 'delete')} onClick={props.onDelete}>
					{props.deleteIcon}
				</span>
			)}
			{props.showLength && props.value && (
				<span className={props.styling?.getStyle(styles, 'length')}>{props.value.length}</span>
			)}
		</div>
	);
};

const TextField = withStyling(component);
export { TextField };
