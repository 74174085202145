import { Pagination as PaginationCore, IPaginationProps as IPaginationPropsCore } from '../core/pagination';
import { Icon } from 'components/icon/icon';

import styles from './pagination.module.scss';

export interface IPaginationProps extends IPaginationPropsCore {}

const Pagination = (props: IPaginationProps): React.JSX.Element => {
	return (
		<PaginationCore
			{...props}
			propStyles={styles}
			skipButtons={{
				prev: {
					icon: (
						<>
							<Icon reactIcon="MdNavigateBefore" size="20px" />
						</>
					),
					label: 'Vorige'
				},
				next: {
					icon: (
						<>
							<Icon reactIcon="MdNavigateNext" size="20px" />
						</>
					),
					label: 'Volgende'
				}
			}}
		/>
	);
};

export { Pagination };
