import React, { useState, useEffect } from 'react';
import { IInjectedStylingProps, withStyling } from './higher-order';
import clsx from 'clsx';
import { calculatePages } from './calculate-pages';
import styles from './pagination.module.scss';

export interface IPaginationProps extends IInjectedStylingProps {
	absoluteCenterPages?: boolean;
	autoWidth?: boolean;
	currentPage: number;
	isMobile?: boolean;
	mobileCenterText?: string;
	onPageChange: (page: number) => void;
	skipButtons?: IPaginationSkips;
	totalPages: number;
	href?: string;
	linkElement?: React.JSX.Element;
	elementIsNextJsLink?: boolean;
}

export interface IPaginationSkips {
	prev: IPaginationSkip;
	next: IPaginationSkip;
}

export interface IPaginationSkip {
	icon?: React.JSX.Element;
	label?: string;
}

const PaginationComponent = (props: IPaginationProps): React.JSX.Element => {
	const [pages, setPages] = useState<number[]>([]);

	useEffect(() => {
		setPages(calculatePages(props.totalPages, props.currentPage));
	}, [props.totalPages, props.currentPage]);

	if (props.totalPages <= 1) {
		return <></>;
	}

	return (
		<div
			className={clsx(
				props.styling?.getStyle(styles, 'pagination'),
				props.isMobile && props.styling?.getStyle(styles, 'isMobile'),
				props.autoWidth && props.styling?.getStyle(styles, 'autoWidth')
			)}
		>
			{props.skipButtons?.prev && renderSkipElement('prev', props)}

			<div
				className={clsx(
					props.styling?.getStyle(styles, 'pageNumbers'),
					props.absoluteCenterPages && props.styling?.getStyle(styles, 'absoluteCenter')
				)}
			>
				{props.isMobile ? (
					<>
						<span
							className={clsx(
								props.styling?.getStyle(styles, 'pageNumber'),
								props.styling?.getStyle(styles, 'isSelected')
							)}
						>
							{props.currentPage}
						</span>
						<span className={props.styling?.getStyle(styles, 'mobileCenterText')}>{props.mobileCenterText}</span>
						<span className={props.styling?.getStyle(styles, 'pageNumber')}>{props.totalPages}</span>
					</>
				) : (
					pages.map((page, index) => (
						<React.Fragment key={page}>
							{renderPageElement(page, props)}
							{page + 1 !== pages[index + 1] && index !== pages.length - 1 && (
								<span className={props.styling?.getStyle(styles, 'pageNumber')}>...</span>
							)}
						</React.Fragment>
					))
				)}
			</div>
			{props.skipButtons?.next && renderSkipElement('next', props)}
		</div>
	);
};

const Pagination = withStyling(PaginationComponent);
export { Pagination };

const renderPageElement = (page: number, props: IPaginationProps): React.JSX.Element => {
	const classNames = clsx(
		props.styling?.getStyle(styles, 'pageNumber'),
		page === props.currentPage && props.styling?.getStyle(styles, 'isSelected')
	);

	if (props.href) {
		return cloneLinkElement(
			page,
			`${props.href}${page}`,
			props.linkElement,
			{ className: classNames },
			props.elementIsNextJsLink
		);
	}
	return (
		<button className={classNames} type="button" onClick={(): void => props.onPageChange(page)}>
			{page}
		</button>
	);
};

const renderSkipElement = (type: 'prev' | 'next', props: IPaginationProps): React.JSX.Element => {
	const isDisabled = type === 'prev' ? props.currentPage === 1 : props.currentPage === props.totalPages;
	const hasIcon = props.skipButtons?.[type].icon;
	const hasLabel = props.skipButtons?.[type].label;
	const newPage = getNewPage(type, props.currentPage, props.totalPages);

	const sameProps = {
		className: clsx(
			props.styling?.getStyle(styles, 'skip'),
			props.styling?.getStyle(styles, type),
			isDisabled && props.styling?.getStyle(styles, 'disabled'),
			hasIcon && !hasLabel && props.styling?.getStyle(styles, 'iconOnly')
		),
		tabIndex: isDisabled ? -1 : 0
	};

	const content = (
		<>
			{props.skipButtons?.[type].icon && (
				<div className={props.styling?.getStyle(styles, 'icon')}>{props.skipButtons[type].icon}</div>
			)}
			{props.skipButtons?.[type].label && (
				<div className={clsx(props.styling?.getStyle(styles, 'label'))}>{props.skipButtons[type].label}</div>
			)}
		</>
	);

	if (props.href && props.linkElement) {
		return renderSkipLink(props, content, sameProps, Number(newPage));
	}

	return renderSkipButton(props, content, sameProps, Number(newPage));
};

const renderSkipButton = (
	props: IPaginationProps,
	content: React.JSX.Element,
	buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>,
	newPage: number
): React.JSX.Element => (
	<button
		{...buttonProps}
		type="button"
		onClick={() => {
			if (newPage !== undefined) {
				props.onPageChange(newPage);
			}
		}}
	>
		{content}
	</button>
);

const renderSkipLink = (
	props: IPaginationProps,
	content: React.JSX.Element,
	linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement>,
	newPage: number
): React.JSX.Element => {
	const href = newPage && props.href ? `${props.href}${newPage}` : '';

	return cloneLinkElement(content, href, props.linkElement, linkProps, props.elementIsNextJsLink);
};

const getNewPage = (type: 'prev' | 'next', currentPage: number, totalPages: number): number | undefined => {
	if (type === 'prev') {
		if (currentPage - 1 >= 1) {
			return currentPage - 1;
		}
	} else if (currentPage + 1 <= totalPages) {
		return currentPage + 1;
	}

	return undefined;
};

const cloneLinkElement = (
	children: React.JSX.Element | string | number,
	href: string,
	linkElement?: React.JSX.Element,
	anchorProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>,
	elementIsNextJsLink?: boolean
): React.JSX.Element => {
	if (!linkElement) return <></>;

	return elementIsNextJsLink
		? React.cloneElement(linkElement, {
				href,
				children: (
					<a {...anchorProps} href={href}>
						{children}
					</a>
				)
		  })
		: React.cloneElement(linkElement, { ...anchorProps, children, href, to: href });
};
